import { Project } from "../types";

export const hod: Project = {
  id: 2,
  locked: true,
  shortie: true,
  name: {
    en: "Hod",
    ru: "Ход",
  },
  similarCases: [1, 3, 4],
  type: {
    en: "Personal",
    ru: "Личное",
  },
  year: "Coming soon...",
  timeline: {
    en: "Sep'22 — Today",
    ru: "Сен'22 — Сейчас",
  },
  short_deliverables: {
    en: "Brand & Product",
    ru: "Бренд и продукт",
  },
  deliverables: {
    en: "Branding, Identity, UI/UX, Analytics, Promotion, Development",
    ru: "Брендинг, Айдентика, UI/UX, Аналитика, Продвижение, Разработка",
  },
  filterTags: [
    {
      name: { en: "Product design", ru: "Продуктовый дизайн" },
      value: "product",
    },
    {
      name: { en: "UI/UX design", ru: "UI/UX" },
      value: "uiux",
    },
    {
      name: { en: "Development", ru: "Разработка" },
      value: "dev",
    },
  ],
  role: {
    en: "Product designer, Developer",
    ru: "Продуктовый дизайнер, разработчик",
  },
  organisation: "HSE ART AND DESIGN SCHOOL",
  status: {
    en: "Launch",
    ru: "Запуск",
  },
  s_description: {
    en: "Hod is an initiative tracker for D&D, companion for players and GMs.",
    ru: "Ход — это трекер инициативы для D&D, компаньон для игроков и мастеров.",
  },
  description: {
    en: "Hod allows you to track initiative, concentration and states of heroes or NPCs. App counts all the necessary numbers for the GMs, allows players to synchronously follow the game process and makes your hobby more accessible than ever. With Hod you can focus on the game, not on counting numbers. Roll the dice, baby!",
    ru: "«Ход» позволяет отслеживать инициативу, концентрацию и состояния героев и неигровых персонажей. Приложение считает за мастеров все необходимые цифры, позволяет игрокам синхронно следить за процессом игры и делает хобби доступнее, чем когда-либо. С помощью «Хода» вы можете сосредоточиться на игре, а не на подсчёте цифр. Roll the dice, baby!",
  },
  vertical_cover:
    "https://firebasestorage.googleapis.com/v0/b/ttovarischh-9c624.appspot.com/o/casesCovers%2FhodVerticalCover.webp?alt=media&token=fa6f60dd-dcd3-4341-bb6a-45a2fd1c4702",
  horisontal_cover:
    "https://firebasestorage.googleapis.com/v0/b/ttovarischh-9c624.appspot.com/o/casesCovers%2Fhodcover2.webp?alt=media&token=b2c79f47-cbbf-4657-8a16-cc19dac11fe5",
  cover:
    "https://firebasestorage.googleapis.com/v0/b/ttovarischh-9c624.appspot.com/o/casesCovers%2Fhodcover2.webp?alt=media&token=b2c79f47-cbbf-4657-8a16-cc19dac11fe5",
  cover_mob:
    "https://firebasestorage.googleapis.com/v0/b/ttovarischh-9c624.appspot.com/o/casesCovers%2Fwoverticaltextfin.mp4?alt=media&token=d6307ae5-09e7-4e03-a7e8-6f4fafeecbb4",
  links: [
    {
      name: {
        en: "Full presentation",
        ru: "Презентация",
      },
      url: "https://portfolio.hse.ru/Project/182542",
    },
    {
      name: {
        en: "Landing",
        ru: "Лендинг",
      },
      url: "https://its-all-taken.github.io/hod-landing/",
    },
  ],
  slider: [],
  link_cards: [],
  images: [],
  texts: [
    {
      header: [{ en: "Context and problem", ru: "Проблематика и контекст" }],
      text: [
        {
          en: "According to statistics[1][2], the main reasons for listening to music are mood enhancement, stress relief, and support for overall mental well-being. However, existing music services do not take this context into account.",
          ru: "Согласно статистике[1][2], основными причинами прослушивания музыки являются улучшение настроения, борьба со стрессом, поддержка общего ментального состояния. Однако существующие музыкальные сервисы не учитывают этот контекст.",
        },
      ],
      links: [
        {
          text: [{ en: "1", ru: "1" }],
          url: "https://www.ncbi.nlm.nih.gov/pmc/articles/PMC3741536/",
        },
        {
          text: [{ en: "2", ru: "2" }],
          url: "https://www.ncbi.nlm.nih.gov/pmc/articles/PMC3741536/table/TA3/?report=objectonly",
        },
      ],
    },
    {
      header: [
        { en: "Research & target audience", ru: "Исследования и анализ ЦА" },
      ],
      text: [
        {
          en: "We conducted a study by surveying 81 people. The goal was to determine how users listen to music, what role it plays in their lives, and to understand the context in which they exist. As a result, three audience segments were identified.",
          ru: "Мы провели опрос, целью которого было понять, как пользователь подходит к задаче прослушивания музыки и какое место она занимает в его жизни, а также понять, в каком контексте он существует. В результате были выделены 3 сегмента ЦА.",
        },
      ],
    },
    {
      header: [
        { en: "About our competitors \n♞", ru: "Про наших конкурентов\n♞" },
      ],
      text: [
        {
          en: "In the course of the research, we discovered that there are no analogs to Wō. While there are similar products, none address our specific task. Our direct competitors include Endel, Mood Playlist, and My wave. Indirect competitors may include music therapy apps, meditation applications, and online psychotherapy services.",
          ru: "В процессе исследования мы выяснили, что у Wō нет аналога. Есть похожие продукты, но нашу конкретную задачу не решает никто. Например, нашими прямыми конкурентами были Endel, Mood Playlist и «Моя волна». Косвенными конкурентами могут считаться сервисы с муз. терапией и психотерапией, приложения для медитации.",
        },
      ],
    },
    {
      header: [
        {
          en: "Visual research book",
          ru: "Книга-визуальное исследование",
        },
      ],
      text: [
        {
          en: "Deep dive into the topic prompted me to trace the origins of the previously described problem and how it has evolved over the centuries. All of this formed the basis of my visual research book. By the way, it is available online via the link.",
          ru: "Глубокое погружение в тему побудило меня проследить, откуда возникла описанная ранее проблема и как она развивалась на протяжении столетий. Это легло в основу книги-визуального исследования. Кстати, в электронном виде она доступна по ссылке.",
        },
      ],
      links: [
        {
          text: [{ en: "via the link", ru: "по ссылке" }],
          url: "https://www.calameo.com/read/0064920654d449caed04d",
        },
      ],
    },
    {
      header: [{ en: "Problem Interview", ru: "Проблемное интервью" }],
      text: [
        {
          en: "To refine the product, we conducted problem-focused research by interviewing 20 people. During the interviews, 6/7 hypotheses were confirmed. Insights from the research later contributed to the brand development and the technical aspects of the product.",
          ru: "Для более тщательной проработки продукта мы провели проблемные исследования, поговорив c 20 респондентами. В ходе интервью 6/7 гипотез подтвердились. Инсайты из исследования позже помогли в разработке бренда и технической части продукта.",
        },
      ],
      links: [
        {
          text: [
            { en: "problem-focused research", ru: "проблемные исследования" },
          ],
          url: "https://tinyurl.com/45wzju3u",
        },
      ],
    },
    {
      header: [{ en: "Branding & Identity", ru: "Брендинг и айдентика" }],
      text: [
        {
          en: "To explain and convey the core value of our product to the audience, we created a brand — Wō (from Chinese «shelter»). Its mission is to make emotions clear for people. This is why we turn to the universal language — music.",
          ru: "Чтобы объяснить и донести основную ценность нашего продукта до аудитории, мы создали бренд — Wō (с кит. «приют»). Его миссия — сделать эмоции понятными для человека. Именно поэтому мы обращаемся к универсальному языку — музыке.",
        },
      ],
    },
    {
      header: [
        { en: "About main metaphor \n⤨", ru: "Об основной метафоре \n⤨" },
      ],
      text: [
        {
          en: "Wō (aptly named mood switcher) helps you switch moods. Like a toggle or switch, it changes your mood from the current to the desired one with a snap of your fingers. Our symbol and logo, along with additional graphic elements, are abstract switches.",
          ru: "Wō (не зря mood switcher) помогает переключиться. Как рубильник или свич, он по щелчку пальца меняет настроение с существующего на желаемое. Наш знак и логотип, дополнительные графические элементы — абстрактные переключатели.",
        },
      ],
    },
    {
      header: [
        { en: "About main metaphor \n৲", ru: "О добавочной метафоре \n৲" },
      ],
      text: [
        {
          en: "At the same time, it's important to understand that we don't treat or provide therapy, but act as a mediator between music, the user, and the desired mood. All the connections between the elements, all the smooth bridges — that's what it's all about.",
          ru: "В то же время важно понимать, что мы не лечим и не занимаемся терапией, но становимся медиатором между музыкой, пользователем и желаемым настроением. Все связи между блоками, все обтекаемые мосты — про это.",
        },
      ],
    },
    {
      header: [{ en: "About digital assets", ru: "Про цифровые носители" }],
      text: [
        {
          en: "Examples of our digital assets cover the entire user journey in the funnel: from discovering Wō through social media or banner ads to downloading the app in the store. By the way, over the past year, we made 100+ iterations of banners and identified the ones with the highest conversion rates.",
          ru: "Примеры наших цифровых носителей охватывают весь путь пользователя в воронке: от знакомства с Wō через соцсети или рекламные баннеры до скачивания приложения в сторе. Кстати, за год мы сделали 100+ итераций баннеров и нашли те, что показали самую высокую конверсию.",
        },
      ],
    },
    {
      header: [{ en: "About MVP1", ru: "Про MVP1" }],
      text: [
        {
          en: "MVP1 was a landing page that collected nicks for access to the upcoming beta. The primary goal of the research was to determine how interested the TA was in the product. We gathered 27 responses w/ minimal advertising costs.",
          ru: "Нашим первый MVP стал лендинг, собиравший тг-ники для доступа к будущей бете. Задачей исследования было, в первую очередь, определить, насколько высок интерес ЦА к продукту. Собрали 27 откликов при минимальных затратах на рекламу.",
        },
      ],
    },
    {
      header: [{ en: "About MVP2", ru: "Про нашего\nтг-бота\nッ" }],
      text: [
        {
          en: "To test the product mechanics, we created MVP2 — a bot with the Luscher test that curates music based on the user's goals and mood using ChatGPT API. Its secondary goal was to build an audience before the subscription launch.",
          ru: "Для проверки механик продукта мы создали MVP2 — Telegram-бота с тестом Люшера, подбиравшего музыку под цель и настроение пользователя с помощью ChatGPT API. Его побочной целью был сбор аудитории перед запуском подписки.",
        },
      ],
    },
    {
      header: [{ en: "About social media", ru: "Про каналы продвижения\n☄" }],
      text: [
        {
          en: "Also we developed our communities. The content categories included announcements, posts about the history of emotions and their reflection in music, insights from casdev, and interactives. Plus, we created 20 mood playlists on Yandex Music under the Wō brand.",
          ru: "Параллельно мы развивали наши сообщества. Рубрикатор включал анонсы, посты о истории эмоций и их отражении в музыке, инсайты из касдева и интерактивов. Также на Я.Музыке было создано 20 mood-плейлистов под брендом Wō.",
        },
      ],
    },
    {
      header: [{ en: "Market analysis", ru: "Бизнес-аналитика" }],
      text: [
        {
          en: "To understand how to sell it, we assessed the market potential and the product's ability to generate revenue. PESTEL and SWOT analyses provided insights into the project's strengths and weaknesses and how to mitigate threats by leveraging its strengths.",
          ru: "Для того, чтобы понять как это продавать, мы просчитали потенциал рынка и способность продукта генерировать деньги. PESTEL и SWOT дали нам понимание, какие у проекта преимущества и недостатки, и как снизить угрозы за счет сильных сторон.",
        },
      ],
      links: [
        {
          text: [{ en: "the market potential", ru: "потенциал рынка" }],
          url: "https://www.figma.com/design/ZlOtk8Vhh97tBSRWBRtT1t/Shareable?node-id=179-2201&t=npGiMj53g40iHfme-1",
        },
      ],
    },
    {
      header: [
        {
          en: "Qualitative research & MVP3",
          ru: "Качественное исследование & MVP3",
        },
      ],
      text: [
        {
          en: "Solution Interviews confirmed that the basic mechanics are clear to people. The final version of the MVP was created to attract initial sales and validate the product's viability. Subscriptions were purchased directly within the bot, without transitioning to third-party applications.",
          ru: "Решенческие интервью подтвердили: базовая механика понятна людям. Финальная версия MVP была сделана для привлечения первых продаж и проверки жизнеспособности продукта. Подписка покупалась прямо в боте, без перехода в сторонние приложения.",
        },
      ],
    },
    {
      header: [
        {
          en: "Second landing & MVP3 promotion",
          ru: "Второй лендинг & продвижение MVP3",
        },
      ],
      text: [
        {
          en: "To promote MVP3, a second landing page was created. Its secondary goal was to increase the number of subscribers in the communities. The site attracted 617 unique users, and the conversion rate for the goals was 1.91%.",
          ru: "Для продвижения MVP3 был создан второй лендинг. Его побочной целью было увеличить количество подписчиков в сообществах. 617 уникальных пользователей посетили сайт, конверсия по целям составила 1.91%.",
        },
      ],
      links: [
        {
          text: [{ en: "second landing page", ru: "второй лендинг" }],
          url: "https://wo.artdesignandprooomotion.ru/",
        },
      ],
    },
    {
      header: [{ en: "About numbers", ru: "Про цифры" }],
      text: [
        {
          en: "Over the year, we spent 317k tokens, launched 14 ad. campaigns, and created 6.3k Figma frames. Wō was also presented at 1 international forum, receiving 1 business proposal. Reached ~91k users, and earned 2k from sales.",
          ru: "За год потратили 317к токенов, запустлии 14 рекламных кампаний, создали 6.3к фреймов. А еще презентовали Wō на 1 международном форуме, получив 1 предложение по запуску проекта. Охватили ~91к пользователей и заработали 2к с продаж.",
        },
      ],
      links: [
        {
          text: [{ en: "1 international forum", ru: "1 международном форуме" }],
          url: "https://facecast.net/v/ntqgl4?t=18591s",
        },
        {
          text: [{ en: "1 competition", ru: "1 конкурс" }],
          url: "https://www.ncbi.nlm.nih.gov/pmc/articles/PMC3741536/table/TA3/?report=objectonly",
        },
      ],
    },
  ],
  layout: [
    {
      component: "EmblaCarousel",
      sliderIndex: 0,
      references: "about",
    },
    {
      component: "A_InfoBlock",
      textIndex: 0,
      $body: true,
      references: "contextproblem",
    },
    {
      component: "M_CaseImagesGrid",
      imageIndices: [0, 1],
    },
    {
      component: "A_InfoBlock",
      textIndex: 1,
      $body: true,
      references: "researchta",
    },
    {
      component: "EmblaCarousel",
      sliderIndex: 1,
    },
    { component: "A_InfoCard", textIndex: 2, references: ["research"] },
    {
      component: "M_CaseImagesGrid",
      imageIndices: [2, 3],
      references: "trendbench",
    },
    {
      component: "A_InfoBlock",
      textIndex: 3,
      $body: true,
      references: "visualresearch",
    },
    {
      component: "EmblaCarousel",
      sliderIndex: 2,
    },
    {
      component: "M_CaseImagesGrid",
      imageIndices: [4, 5, 6, 7, 8, 9],
    },
    {
      component: "A_InfoBlock",
      textIndex: 4,
      $body: true,
      references: "probleminterview",
    },
    {
      component: "M_CardsBlocksGrid",
      smallCardIndex: [0, 1, 2],
      references: "interview",
    },
    {
      component: "A_InfoBlock",
      textIndex: 5,
      $body: true,
      references: "brand",
    },
    {
      component: "EmblaCarousel",
      sliderIndex: 3,
      references: "branding",
    },
    {
      component: "M_LinkCardsGrid",
      linkCardIndex: [0, 1],
      references: ["userflow", "brandplatform"],
    },
    {
      component: "EmblaCarousel",
      sliderIndex: 4,
      references: "identity",
    },
    {
      component: "A_InfoCard",
      textIndex: [6, 7],
      references: "aboutassets1",
    },
    {
      component: "EmblaCarousel",
      sliderIndex: 5,
      references: "pmerch",
    },
    { component: "M_CaseImagesGrid", imageIndices: [10, 11, 12] },
    {
      component: "M_LinkCardsGrid",
      linkCardIndex: [2],
      references: ["styleguide"],
    },
    { component: "M_CaseImagesGrid", imageIndices: [13, 14, 15, 16, 17] },
    {
      component: "A_InfoBlock",
      textIndex: 8,
      $body: true,
      references: "dassets",
    },
    {
      component: "EmblaCarousel",
      sliderIndex: 6,
    },
    { component: "M_CaseImagesGrid", imageIndices: [18, 19, 20] },
    {
      component: "A_InfoBlock",
      textIndex: 9,
      $body: true,
      references: "product",
    },
    {
      component: "M_CaseImagesGrid",
      imageIndices: [21, 22],
      references: "MVP1",
    },
    {
      component: "M_LinkCardsGrid",
      linkCardIndex: [3],
      references: ["designsystem"],
    },
    {
      component: "EmblaCarousel",
      sliderIndex: 7,
      references: "scenarios",
    },
    {
      component: "M_UIShow",
      uiShowIndex: 0,
      references: "scenario1",
    },
    {
      component: "M_UIShow",
      uiShowIndex: 1,
      references: "scenario2",
    },
    {
      component: "M_UIShow",
      uiShowIndex: 2,
      references: "scenario3",
    },
    {
      component: "A_InfoCard",
      textIndex: [10, 11],
      references: "mvp2",
    },
    {
      component: "EmblaCarousel",
      sliderIndex: 8,
      references: "mediachannels",
    },
    {
      component: "A_InfoBlock",
      textIndex: 12,
      $body: true,
      references: "marketanalysis",
    },
    {
      component: "EmblaCarousel",
      sliderIndex: 9,
      references: "market",
    },
    {
      component: "A_InfoBlock",
      textIndex: 13,
      $body: true,
      references: "mvp3",
    },
    {
      component: "M_CaseImagesGrid",
      imageIndices: [23, 24],
    },
    {
      component: "A_InfoBlock",
      textIndex: 14,
      $body: true,
      references: "mvp3promo",
    },
    {
      component: "M_CaseImagesGrid",
      imageIndices: [25, 26, 27],
    },
    {
      component: "A_InfoBlock",
      textIndex: 15,
      $body: true,
      references: "mvp3promo",
    },
    {
      component: "EmblaCarousel",
      sliderIndex: 10,
      references: "allres",
    },
  ],
  menuItems: [
    {
      header: [
        {
          title: {
            en: "—\u2002About Wo",
            ru: "—\u2002О проекте",
          },
          reference: "about",
        },
      ],
      subItems: [
        {
          title: {
            en: "Context & Problem",
            ru: "Контекст и проблематика",
          },
          reference: "contextproblem",
        },
        {
          title: {
            en: "Survey & TA",
            ru: "Опрос и ЦА",
          },
          reference: "researchta",
        },
      ],
    },
    {
      header: [
        {
          title: {
            en: "—\u2002Research",
            ru: "—\u2002Ресерч",
          },
          reference: "research",
        },
      ],
      subItems: [
        {
          title: {
            en: "Trendwatching & Benchmarking",
            ru: "Трендвотчинг и бенчмаркинг",
          },
          reference: "trendbench",
        },
        {
          title: {
            en: "Visual Research",
            ru: "Визуальное исследование",
          },
          reference: "visualresearch",
        },
        {
          title: {
            en: "Problem Interview",
            ru: "Проблемное интервью",
          },
          reference: "probleminterview",
        },
        {
          title: {
            en: "Insights from TA",
            ru: "Инсайты из интервью",
          },
          reference: "interview",
        },
      ],
    },
    {
      header: [
        {
          title: {
            en: "—\u2002Branding & Identity",
            ru: "—\u2002Брендинг и айдентика",
          },
          reference: "brand",
        },
      ],
      subItems: [
        {
          title: {
            en: "Brand Components",
            ru: "Составляющие бренда",
          },
          reference: "branding",
        },
        {
          title: {
            en: "Userflow",
            ru: "Юзерфлоу",
          },
          reference: "userflow",
        },
        {
          title: {
            en: "Brand Platform",
            ru: "Платформа бренда",
          },
          reference: "brandplatform",
        },
        {
          title: {
            en: "Identity",
            ru: "Айдентика",
          },
          reference: "identity",
        },
        {
          title: {
            en: "About identity components",
            ru: "Про носители",
          },
          reference: "aboutassets1",
        },
        {
          title: {
            en: "Physical merch",
            ru: "Физические носители",
          },
          reference: "pmerch",
        },
        {
          title: {
            en: "Styleguide",
            ru: "Стайлгайд",
          },
          reference: "styleguide",
        },
        {
          title: {
            en: "Digital assets",
            ru: "Диджитальные носители",
          },
          reference: "dassets",
        },
      ],
    },
    {
      header: [
        {
          title: {
            en: "—\u2002Product",
            ru: "—\u2002Продукт",
          },
          reference: "product",
        },
      ],
      subItems: [
        {
          title: {
            en: "About MVP1",
            ru: "Про MVP1",
          },
          reference: "MVP1",
        },
        {
          title: {
            en: "Design System",
            ru: "Дизайн-система",
          },
          reference: "designsystem",
        },
        {
          title: {
            en: "User Scenarios",
            ru: "Сценарии",
          },
          reference: "scenarios",
        },
        {
          title: {
            en: "Scenario I",
            ru: "Сценарий I",
          },
          reference: "scenario1",
        },
        {
          title: {
            en: "Scenario II",
            ru: "Сценарий II",
          },
          reference: "scenario2",
        },
        {
          title: {
            en: "Scenario III",
            ru: "Сценарий III",
          },
          reference: "scenario3",
        },
        {
          title: {
            en: "MVP2 & Promotion",
            ru: "MVP2 & Продвижение",
          },
          reference: "mvp2",
        },
        {
          title: {
            en: "Media channels",
            ru: "Каналы продвижения",
          },
          reference: "mediachannels",
        },
        {
          title: {
            en: "Market Analysis",
            ru: "Анализ рынка",
          },
          reference: "marketanalysis",
        },
        {
          title: {
            en: "Market, Unit economy, Lean Canvas, PESTEL, SWOT, Funnels",
            ru: "Рынок, Юнит-экономика, Лин-канвас, PESTEL, SWOT, Воронки",
          },
          reference: "market",
        },
        {
          title: {
            en: "MVP3",
            ru: "MVP3",
          },
          reference: "mvp3",
        },
        {
          title: {
            en: "MVP3 promotion",
            ru: "Продвижение MVP3",
          },
          reference: "mvp3promo",
        },
        {
          title: {
            en: "Overall results",
            ru: "Результаты",
          },
          reference: "allres",
        },
      ],
    },
  ],
};
