import { Project } from "../types";

export const metallique: Project = {
  id: 6,
  name: {
    en: "Metallique Web Synth",
    ru: "Metallique Web Synth",
  },
  similarCases: [8, 7, 5],
  type: {
    en: "Personal",
    ru: "Личное",
  },
  timeline: {
    en: "Oct'23 — Dec'23",
    ru: "Окт'23 — Дек'23",
  },
  year: "2023",
  short_deliverables: {
    en: "UI/UX & Dev",
    ru: "UI/UX & Разработка",
  },
  deliverables: {
    en: "UI/UX, Development",
    ru: "UI/UX, Разработка",
  },
  filterTags: [
    {
      name: { en: "UI/UX design", ru: "UI/UX" },
      value: "uiux",
    },
    {
      name: { en: "Development", ru: "Разработка" },
      value: "dev",
    },
  ],
  role: {
    en: "UI/UX designer, Developer",
    ru: "UI/UX дизайнер, разработчик",
  },
  organisation: "HSE ART AND DESIGN SCHOOL",
  status: {
    en: "Finished",
    ru: "Завершено",
  },
  s_description: {
    en: "A small but brutal web synthesizer with a full metal vibe.",
    ru: "Брутальный веб-синтезатор с цельнометаллическим вайбом.",
  },
  description: {
    en: "The concept sounds pretty dumb, but I’m just being honest. Metallique is a web synthesizer that simulates the operation of a device capable of producing sounds with an industrial vibe (as I imagine it in my head), thereby creating a unique kind of music. Just out of respect for «Final Muzak».",
    ru: "Концепция звучит довольно глупо, но я просто буду честна. Metallique — это веб-синтезатор, который имитирует работу некоего устройства, способного воспроизводить звуки с индустриальным вайбом (каким я его представляю в своей голове), тем самым создавая своеобразную музыку. Просто из уважения к «Final Muzak».",
  },
  vertical_cover:
    "https://firebasestorage.googleapis.com/v0/b/ttovarischh-9c624.appspot.com/o/metallique%2FmetalVertiCover.webp?alt=media&token=31a571d1-bcc9-4818-a76d-ad08e0a10bc4",
  horisontal_cover:
    "https://firebasestorage.googleapis.com/v0/b/ttovarischh-9c624.appspot.com/o/metallique%2Fmwshorcover.webp?alt=media&token=ddcb0f78-5bdb-4821-8a81-77b383ac5e2c",
  cover:
    "https://firebasestorage.googleapis.com/v0/b/ttovarischh-9c624.appspot.com/o/metallique%2FpcCover.webp?alt=media&token=2ef43781-1a8e-4aa1-85a7-508c7355fc38",
  cover_mob:
    "https://firebasestorage.googleapis.com/v0/b/ttovarischh-9c624.appspot.com/o/metallique%2FmobCover.webp?alt=media&token=1398c95c-a79d-4812-a3b5-e63de510d87f",
  links: [
    {
      name: {
        en: "Full presentation",
        ru: "Презентация",
      },
      url: "https://portfolio.hse.ru/Project/165631",
    },
    {
      name: {
        en: "Behance",
        ru: "Behance",
      },
      url: "https://www.behance.net/gallery/207225153/Metallique-Synth",
    },
  ],
  slider: [],
  link_cards: [
    {
      image_src:
        "https://firebasestorage.googleapis.com/v0/b/ttovarischh-9c624.appspot.com/o/metallique%2Fmetallilink.webp?alt=media&token=133536d8-00b0-493d-895b-e1379fffb717",
      header: [{ en: "Github", ru: "Github" }],
      text: [
        {
          en: "The synthesizer’s code is located in an open Github repository. If you want, you can run it locally.",
          ru: "Код синтезатора находится в открытом репозитории на Github. При желании его можно запустить локально.",
        },
      ],
      link_text: [{ en: "Go to repo", ru: "К репозиторию" }],
      url: "https://github.com/ttovarischh/metallique",
    },
  ],
  images: [
    {
      src: "https://firebasestorage.googleapis.com/v0/b/ttovarischh-9c624.appspot.com/o/metallique%2F1b.webp?alt=media&token=52a645b0-7cb4-4b31-abb5-5ada04e7957b",
    },
    {
      src: "https://firebasestorage.googleapis.com/v0/b/ttovarischh-9c624.appspot.com/o/metallique%2F2.webp?alt=media&token=513d1b8a-bc21-43ed-8b64-66b3ad0b6ae7",
    },
    {
      src: "https://firebasestorage.googleapis.com/v0/b/ttovarischh-9c624.appspot.com/o/metallique%2F3.webp?alt=media&token=1a00380c-302a-4c0e-a9b1-9a8f67cf11f2",
    },
    {
      src: "https://firebasestorage.googleapis.com/v0/b/ttovarischh-9c624.appspot.com/o/metallique%2F4.webp?alt=media&token=9a25e61d-951e-47ad-994f-4ddb693d35cd",
    },
    {
      src: "https://firebasestorage.googleapis.com/v0/b/ttovarischh-9c624.appspot.com/o/metallique%2F4site.gif?alt=media&token=3957d5ec-bf42-4859-9f4a-4e5ae1d90441",
    },
    {
      src: "https://firebasestorage.googleapis.com/v0/b/ttovarischh-9c624.appspot.com/o/metallique%2F5site.gif?alt=media&token=16663102-bae2-40dd-86f8-a2a6d8aa4b79",
    },
    {
      src: "https://firebasestorage.googleapis.com/v0/b/ttovarischh-9c624.appspot.com/o/metallique%2F7.webp?alt=media&token=4671e2d4-5866-44a2-84b1-47f43b346ae3",
    },
    {
      src: "https://firebasestorage.googleapis.com/v0/b/ttovarischh-9c624.appspot.com/o/metallique%2Fmetallique_synth.mp4?alt=media&token=8b22a121-c22e-40f0-9932-2c5ceddd960c",
      poster:
        "https://firebasestorage.googleapis.com/v0/b/ttovarischh-9c624.appspot.com/o/metallique%2Fposter.webp?alt=media&token=c3cf5b6a-2d4d-43d6-b56c-a51cef42e984",
    },
    {
      src: "https://firebasestorage.googleapis.com/v0/b/ttovarischh-9c624.appspot.com/o/metallique%2FcaseFin.gif?alt=media&token=4e9ce790-c64d-4708-a40f-2fa65985be6c",
    },
  ],
  texts: [
    {
      header: [{ en: "About style", ru: "Про стиль" }],
      text: [
        {
          en: "This project turned out to be much more decorative than any of my previous cases. I’ve always been drawn to the concept of phygital, and I fondly remember the days of skeuomorphism in Apple design. Perhaps this nostalgic feeling is what made me want to create something semi-physical and allow myself more creative freedom.",
          ru: "Этот проект получился гораздо более декоративным, чем любой из моих предыдущих кейсов. Меня всегда привлекала концепция фиджитала, я с любовью вспоминаю времена скевоморфизма в дизайне Apple. Возможно из-за этого ностальгического чувства мне и захотелось создать что‑то околофизическое.",
        },
      ],
    },
    {
      header: [{ en: "About code", ru: "Про код" }],
      text: [
        {
          en: "It was my second project in the creative coding course and my second experiment with ToneJS. I genuinely enjoy this tool and the freedom it provides. The project stack is Ruby on Rails + React. All interface components are structured according to atomic design principles.",
          ru: "Это был мой второй проект на курсе креативного кодинга и второй же эксперимент с ToneJS. Мне искренне нравится этот инструмент и свобода действий, которую он предоставляет. Стек проекта — Ruby on Rails + React. Все составляющие интерфейса выстроены по принципу атомарного дизайна.",
        },
      ],
      links: [
        {
          text: [{ en: "1", ru: "1" }],
          url: "https://www.ncbi.nlm.nih.gov/pmc/articles/PMC3741536/",
        },
        {
          text: [{ en: "2", ru: "2" }],
          url: "https://www.ncbi.nlm.nih.gov/pmc/articles/PMC3741536/table/TA3/?report=objectonly",
        },
      ],
    },
  ],
  layout: [
    {
      component: "M_CaseImagesGrid",
      imageIndices: [0, 1, 2],
      references: "synth",
    },
    {
      component: "A_InfoBlock",
      textIndex: 0,
      $body: true,
      references: "style",
    },
    {
      component: "M_CaseImagesGrid",
      imageIndices: [3, 4, 5, 6],
      references: "idea",
    },
    {
      component: "A_InfoBlock",
      textIndex: 1,
      $body: true,
      references: "code",
    },
    {
      component: "M_CaseImagesGrid",
      imageIndices: [7],
      references: "screencast",
    },
    {
      component: "M_LinkCardsGrid",
      linkCardIndex: [0],
      references: ["repo"],
    },
    {
      component: "M_CaseImagesGrid",
      imageIndices: [8],
      references: "fin",
    },
  ],
  menuItems: [
    {
      header: [
        {
          title: {
            en: "—\u2002About synth, background, concept",
            ru: "—\u2002О синте, предыстория, концепт",
          },
          reference: "synth",
        },
      ],
    },
    {
      header: [
        {
          title: {
            en: "—\u2002Structure",
            ru: "—\u2002Построение",
          },
          reference: "style",
        },
      ],
      subItems: [
        {
          title: {
            en: "Idea, palette, controls, sound structure",
            ru: "Задумка, палитра, контролы, структура звука",
          },
          reference: "idea",
        },
      ],
    },
    {
      header: [
        {
          title: {
            en: "—\u2002Product",
            ru: "—\u2002Продукт",
          },
          reference: "code",
        },
      ],
      subItems: [
        {
          title: {
            en: "Screencast",
            ru: "Скринкаст",
          },
          reference: "screencast",
        },
        {
          title: {
            en: "Repo",
            ru: "Репозиторий",
          },
          reference: "repo",
        },
        {
          title: {
            en: "That's all folks",
            ru: "That's all folks",
          },
          reference: "fin",
        },
      ],
    },
  ],
};
