// src/firebase.js
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getPerformance } from "firebase/performance";
import { getStorage } from "firebase/storage";

const firebaseConfig = {
  apiKey: "AIzaSyDcR2kjrlEmIdHiNQzZeR31y4YzcRld7VQ",
  authDomain: "ttovarischh-9c624.firebaseapp.com",
  projectId: "ttovarischh-9c624",
  storageBucket: "ttovarischh-9c624.appspot.com",
  messagingSenderId: "204395125602",
  appId: "1:204395125602:web:cf21d9ef53dea40b7d2a3b",
};

const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
const perf = getPerformance(app);
const storage = getStorage(app); // Initialize and export Firebase Storage

export { app, analytics, perf, storage }; // Export storage to use in other files
