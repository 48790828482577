import { Project } from "../types";

export const quimi: Project = {
  id: 7,
  name: {
    en: "Quimi App",
    ru: "Quimi App",
  },
  similarCases: [2, 3, 4],
  type: {
    en: "Personal",
    ru: "Личное",
  },
  year: "2022",
  timeline: {
    en: "Apr'22 — June'22",
    ru: "Апр'22 — Июнь'22",
  },
  short_deliverables: {
    en: "Brand & Product",
    ru: "Бренд и продукт",
  },
  deliverables: {
    en: "Branding, Identity, UI/UX, Analytics, Promotion, Development",
    ru: "Брендинг, Айдентика, UI/UX, Аналитика, Продвижение, Разработка",
  },
  filterTags: [
    {
      name: { en: "Product design", ru: "Продуктовый дизайн" },
      value: "product",
    },
    {
      name: { en: "UI/UX design", ru: "UI/UX" },
      value: "uiux",
    },
    {
      name: { en: "Art direction", ru: "Арт-дирекшн" },
      value: "artdir",
    },
  ],
  role: {
    en: "Product designer",
    ru: "Продуктовый дизайнер",
  },
  organisation: "HSE ART AND DESIGN SCHOOL",
  status: {
    en: "Finished",
    ru: "Завершено",
  },
  team: [
    {
      imageSrc:
        "https://firebasestorage.googleapis.com/v0/b/ttovarischh-9c624.appspot.com/o/design%2Fmasha.webp?alt=media&token=74f1d18a-714a-4050-b787-479d941877ce",
      link: "https://www.behance.net/kauzt",
    },
    {
      imageSrc:
        "https://firebasestorage.googleapis.com/v0/b/ttovarischh-9c624.appspot.com/o/design%2Fpolina.webp?alt=media&token=d1bec526-b4aa-47ac-ba48-85c4e20d6377",
      link: "https://www.behance.net/sugumisu",
    },
  ],
  s_description: {
    en: "Quimi — exploring the city through the experiences of others.",
    ru: "Quimi — исследование города через опыт других людей.",
  },
  description: {
    en: "This is an app where people share personal memories and impressions of places. This way, they turn ordinary spots on the map into landmarks. The city map in Quimi becomes a library of new iconic locations, a platform for people’s stories.",
    ru: "Это приложение, в котором люди делятся личными воспоминаниями и впечатлениями о местах. Так они превращают обычные точки на карте в достопримечательности. Карта города в Quimi становится библиотекой новых знаковых объектов, платформой для историй людей.",
  },
  vertical_cover:
    "https://firebasestorage.googleapis.com/v0/b/ttovarischh-9c624.appspot.com/o/quimi%2Fquimiverticover.webp?alt=media&token=07e75c51-5dd5-4d39-bcc6-676f62b2b5f9",
  horisontal_cover:
    "https://firebasestorage.googleapis.com/v0/b/ttovarischh-9c624.appspot.com/o/quimi%2Fquimihorcover.webp?alt=media&token=4d7ae037-e67a-449e-ad0e-2ca9172f8935",
  cover:
    "https://firebasestorage.googleapis.com/v0/b/ttovarischh-9c624.appspot.com/o/quimi%2Fquimicoverpc.webp?alt=media&token=38820e28-4d36-4ec8-af48-5ff870a7116c",
  cover_mob:
    "https://firebasestorage.googleapis.com/v0/b/ttovarischh-9c624.appspot.com/o/quimi%2Fquimivercover.webp?alt=media&token=d9b93256-825c-4233-98d3-e89eea00b21e",
  links: [
    {
      name: {
        en: "Full presentation",
        ru: "Презентация",
      },
      url: "https://portfolio.hse.ru/Project/142102",
    },
    {
      name: {
        en: "Behance",
        ru: "Behance",
      },
      url: "https://www.behance.net/gallery/147062693/Quimi-App-with-AR",
    },
  ],
  smallCards: [
    {
      headerCardText: [
        {
          en: "App type: About routes and places",
          ru: "Тип приложения: Про маршруты и места",
        },
      ],
      headerCardIcon: "✦",
      mainCardText: [
        {
          en: "The problem is that locations are always very mainstream. It’s hard to find anything local in such apps. +The interfaces are not always user-friendly.",
          ru: "Проблема в том, что места — всегда очень мейнстримные. В таких приложениях не найти чего‑то локального. +Не всегда удобные интерфейсы.",
        },
      ],
      tags: [
        {
          en: ["Examples", "TripAdvisor", "GoogleMaps", "Izi.Travel"],
          ru: ["Примеры", "TripAdvisor", "GoogleMaps", "Izi.Travel"],
        },
      ],
    },
    {
      headerCardText: [
        {
          en: "App type: Socialization on the map",
          ru: "Тип приложения: Социализация на карте",
        },
      ],
      headerCardIcon: "ᕯ",
      mainCardText: [
        {
          en: "The focus is specifically on interacting with friends. You can't explore without contacts in the app. +There’s often very little content available.",
          ru: "Акцент делается именно на общении с друзьями. Нельзя гулять без контактов в приложении. +Часто там очень мало контента.",
        },
      ],
      tags: [
        {
          en: ["Examples", "Waze", "Zenly"],
          ru: ["Примеры", "Waze", "Zenly"],
        },
      ],
    },
    {
      headerCardText: [
        {
          en: "App type: Gamification of average walks",
          ru: "Тип приложения: Геймификация прогулок",
        },
      ],
      headerCardIcon: "❣",
      mainCardText: [
        {
          en: "The emphasis is either on the visual aspect or the narrative. The concept of drifting, as such, is absent.",
          ru: "Внимание больше уделяется либо визуальной части, либо нарративной. Концепт дрейфа как такового отсутствует.",
        },
      ],
      tags: [
        {
          en: ["Examples", "PokemonGo", "ММХТ", "ArtWalk"],
          ru: ["Примеры", "PokemonGo", "ММХТ", "ArtWalk"],
        },
      ],
    },
  ],
  slider: [
    {
      slides: [
        {
          imgSrc:
            "https://firebasestorage.googleapis.com/v0/b/ttovarischh-9c624.appspot.com/o/quimi%2Fslides%2F1.webp?alt=media&token=ca770327-4099-4243-808d-a9cfce74a4e5",
          description: {
            en: "About Quimi App",
            ru: "Про Quimi App",
          },
        },
        {
          imgSrc:
            "https://firebasestorage.googleapis.com/v0/b/ttovarischh-9c624.appspot.com/o/quimi%2Fslides%2F12.webp?alt=media&token=d768d436-86aa-4865-974b-919ce4d1e5b0",
          description: {
            en: "The idea of an app",
            ru: "Идея приложения",
          },
        },
        {
          imgSrc:
            "https://firebasestorage.googleapis.com/v0/b/ttovarischh-9c624.appspot.com/o/quimi%2Fslides%2F18.webp?alt=media&token=ba489775-2d37-46e8-812a-6d8187b89331",
          description: {
            en: "Problems",
            ru: "Проблематика",
          },
        },
      ],
    },
    {
      slides: [
        {
          imgSrc:
            "https://firebasestorage.googleapis.com/v0/b/ttovarischh-9c624.appspot.com/o/quimi%2Fslides%2F20.webp?alt=media&token=5bafb383-099d-42e0-96e6-63976757f238",
          description: {
            en: "Places to go",
            ru: "Места для прогулок",
          },
        },
        {
          imgSrc:
            "https://firebasestorage.googleapis.com/v0/b/ttovarischh-9c624.appspot.com/o/quimi%2Fslides%2F21.webp?alt=media&token=a88f77aa-6be0-41c8-93af-541942cc1e66",
          description: {
            en: "Where to search",
            ru: "Где искать места",
          },
        },
        {
          imgSrc:
            "https://firebasestorage.googleapis.com/v0/b/ttovarischh-9c624.appspot.com/o/quimi%2Fslides%2F22.webp?alt=media&token=c25303e6-8875-4339-9c63-e4a06ad78e71",
          description: {
            en: "Sharing places",
            ru: "Делимся местами",
          },
        },
        {
          imgSrc:
            "https://firebasestorage.googleapis.com/v0/b/ttovarischh-9c624.appspot.com/o/quimi%2Fslides%2F23.webp?alt=media&token=74f49b11-0089-4671-90d3-c2f485bd2686",
          description: {
            en: "As a keepsake",
            ru: "На память",
          },
        },
      ],
    },
    {
      slides: [
        {
          imgSrc:
            "https://firebasestorage.googleapis.com/v0/b/ttovarischh-9c624.appspot.com/o/quimi%2Fslides%2F2.webp?alt=media&token=6c72004f-7c88-4c2b-a5d7-a36d10cee530",
          description: {
            en: "Getting started",
            ru: "Начало работы",
          },
        },
        {
          imgSrc:
            "https://firebasestorage.googleapis.com/v0/b/ttovarischh-9c624.appspot.com/o/quimi%2Fslides%2F5.webp?alt=media&token=aafa44c4-a824-4400-bfaa-c6d059d3f7cb",
          description: {
            en: "Interacting with friends",
            ru: "Взаимодействия с друзьями",
          },
        },
        {
          imgSrc:
            "https://firebasestorage.googleapis.com/v0/b/ttovarischh-9c624.appspot.com/o/quimi%2Fslides%2F6.webp?alt=media&token=fca63a6e-b432-4232-9e2e-15e163759ea1",
          description: {
            en: "Quimies",
            ru: "Квими",
          },
        },
        {
          imgSrc:
            "https://firebasestorage.googleapis.com/v0/b/ttovarischh-9c624.appspot.com/o/quimi%2Fslides%2F8.webp?alt=media&token=80c82288-266e-44b6-9752-bb9bbfe86fc1",
          description: {
            en: "Quimi creation",
            ru: "Создание квими",
          },
        },
        {
          imgSrc:
            "https://firebasestorage.googleapis.com/v0/b/ttovarischh-9c624.appspot.com/o/quimi%2Fslides%2F7.webp?alt=media&token=50bde2ca-5f8f-4aa5-bdac-140d7c4e416c",
          description: {
            en: "Quimi avatar creation",
            ru: "Создание квими-аватара",
          },
        },
      ],
    },
    {
      slides: [
        {
          imgSrc:
            "https://firebasestorage.googleapis.com/v0/b/ttovarischh-9c624.appspot.com/o/quimi%2Fslides%2F9.webp?alt=media&token=78e97b84-0901-418f-bd9e-3e9e9260bf55",
          description: {
            en: "Tote bags",
            ru: "Шопперы",
          },
        },
        {
          imgSrc:
            "https://firebasestorage.googleapis.com/v0/b/ttovarischh-9c624.appspot.com/o/quimi%2Fslides%2F10.webp?alt=media&token=3fd566f9-3297-49e1-ae3e-b7c87f8a3cf3",
          description: {
            en: "T-shirts",
            ru: "Футболки",
          },
        },
        {
          imgSrc:
            "https://firebasestorage.googleapis.com/v0/b/ttovarischh-9c624.appspot.com/o/quimi%2Fslides%2F11.webp?alt=media&token=507b367a-d3dd-462d-bd6b-6c58e62ac974",
          description: {
            en: "Cap and T-shirt",
            ru: "Кепка и футболка",
          },
        },
        {
          imgSrc:
            "https://firebasestorage.googleapis.com/v0/b/ttovarischh-9c624.appspot.com/o/quimi%2Fslides%2F13.webp?alt=media&token=7d031d9e-d508-4e8c-99be-86dc1889f701",
          description: {
            en: "Bag",
            ru: "Сумка",
          },
        },
      ],
    },
    {
      slides: [
        {
          imgSrc:
            "https://firebasestorage.googleapis.com/v0/b/ttovarischh-9c624.appspot.com/o/quimi%2Fslides%2F15.webp?alt=media&token=711e1223-90b2-4927-bbd7-12bdb2aad45a",
          description: {
            en: "Thank you for your attention!",
            ru: "Спасибо за внимание!",
          },
        },
        {
          imgSrc:
            "https://firebasestorage.googleapis.com/v0/b/ttovarischh-9c624.appspot.com/o/quimi%2Fslides%2F14.webp?alt=media&token=9564c913-4ddd-423e-8f9e-5f5e15178db6",
          description: {
            en: "Sweatshirt and mug",
            ru: "Толстовка и кружка",
          },
        },
        {
          imgSrc:
            "https://firebasestorage.googleapis.com/v0/b/ttovarischh-9c624.appspot.com/o/quimi%2Fslides%2F17.webp?alt=media&token=0151caa3-7db7-4c6b-b37a-be3c7d0c7575",
          description: {
            en: "Sweatshirt and cap",
            ru: "Толстовка и кепка",
          },
        },
        {
          imgSrc:
            "https://firebasestorage.googleapis.com/v0/b/ttovarischh-9c624.appspot.com/o/quimi%2Fslides%2F16.webp?alt=media&token=73f77c19-c539-4a00-917e-3c71efa4e393",
          description: {
            en: "Pencil case, badges and pens",
            ru: "Пенал, значки и ручки",
          },
        },
      ],
    },
  ],
  link_cards: [
    {
      image_src:
        "https://firebasestorage.googleapis.com/v0/b/ttovarischh-9c624.appspot.com/o/quimi%2Fslides%2Fl1.webp?alt=media&token=306aa92b-0eb1-4236-90d2-7444ebb513ba",
      header: [{ en: "Figjam", ru: "Figjam" }],
      text: [
        {
          en: "You can view our fuuuull product research at this link in Figma.",
          ru: "По этой ссылке вы можете посмотреть на наше полноценное исследование.",
        },
      ],
      link_text: [{ en: "See in Figma", ru: "Перейти в Figma" }],
      url: "https://www.figma.com/board/d7M3VGvIp9WIuXKIYaTXAb/progulo4ki",
    },
    {
      image_src:
        "https://firebasestorage.googleapis.com/v0/b/ttovarischh-9c624.appspot.com/o/quimi%2Fslides%2Fl2.webp?alt=media&token=5dd6a660-118e-4b76-bd2a-277f9558728a",
      header: [{ en: "System", ru: "Система" }],
      text: [
        {
          en: "And this link will take you to the atomic and design system of the product.",
          ru: "А по этой ссылке вы перейдёте на атомарную и дизайн-систему продукта.",
        },
      ],
      link_text: [{ en: "See in Figma", ru: "Перейти в Figma" }],
      url: "https://www.figma.com/design/lJQRKpZaoLxzB8YlPkA6yK/ATOMIC-%2B-UI-KIT",
    },
    {
      image_src:
        "https://firebasestorage.googleapis.com/v0/b/ttovarischh-9c624.appspot.com/o/quimi%2Fslides%2Fl3.webp?alt=media&token=acbdf482-7eb1-456c-8f16-844b7e01f78b",
      header: [{ en: "Scenario 1", ru: "Сценарий 1" }],
      text: [
        {
          en: "This screencast demonstrates registration and customization of Quimi.",
          ru: "В этом скринкасте показываем регистрацию и кастомизацию квими.",
        },
      ],
      link_text: [{ en: "Go to Vimeo", ru: "Перейти в Vimeo" }],
      url: "https://vimeo.com/721146762",
    },
    {
      image_src:
        "https://firebasestorage.googleapis.com/v0/b/ttovarischh-9c624.appspot.com/o/quimi%2Fslides%2Fl4.webp?alt=media&token=e3a2ef0d-4ae9-4827-a679-bdae9d0479ec",
      header: [{ en: "Scenario 2", ru: "Сценарий 2" }],
      text: [
        {
          en: "In this scenario, we explore existing Quimi and create another one.",
          ru: "В этом сценарии просматриваем квими и создаем еще один.",
        },
      ],
      link_text: [{ en: "Go to Vimeo", ru: "Перейти в Vimeo" }],
      url: "https://vimeo.com/721146593",
    },
    {
      image_src:
        "https://firebasestorage.googleapis.com/v0/b/ttovarischh-9c624.appspot.com/o/quimi%2Fslides%2Fl5.webp?alt=media&token=a98510fd-be60-41ae-b89b-0a9b02fb315c",
      header: [{ en: "Scenario 3", ru: "Сценарий 3" }],
      text: [
        {
          en: "The final screencast shows interaction capabilities with users.",
          ru: "В последнем скринкасте показываем возможности взаимодействий с юзерами.",
        },
      ],
      link_text: [{ en: "Go to Vimeo", ru: "Перейти в Vimeo" }],
      url: "https://vimeo.com/721146859",
    },
  ],
  images: [
    {
      src: "https://firebasestorage.googleapis.com/v0/b/ttovarischh-9c624.appspot.com/o/quimi%2Fslides%2Fg1.webp?alt=media&token=aa0dd98a-9ef4-4b2d-8ff4-a99ddf38e945",
      description: [{ en: "Target audience", ru: "Целевая аудитория" }],
    },
    {
      src: "https://firebasestorage.googleapis.com/v0/b/ttovarischh-9c624.appspot.com/o/quimi%2Fslides%2Fg2.webp?alt=media&token=d4dc77e8-0cf9-48bf-9265-fef46eb763f2",
      description: [{ en: "Job stories", ru: "Job stories" }],
    },
    {
      src: "https://firebasestorage.googleapis.com/v0/b/ttovarischh-9c624.appspot.com/o/quimi%2Fslides%2F1g3.webp?alt=media&token=ed83a0b1-8ac0-44d6-a9a5-739442ca2ebd",
    },
    {
      src: "https://firebasestorage.googleapis.com/v0/b/ttovarischh-9c624.appspot.com/o/quimi%2Fslides%2Fg4.webp?alt=media&token=803c7a42-2370-4797-bcf0-ef61d27d38de",
    },
    {
      src: "https://firebasestorage.googleapis.com/v0/b/ttovarischh-9c624.appspot.com/o/quimi%2Fslides%2F3g5.webp?alt=media&token=09d7a812-f2bf-4f05-abca-ad9f68e3e88e",
    },
    {
      src: "https://firebasestorage.googleapis.com/v0/b/ttovarischh-9c624.appspot.com/o/quimi%2Fslides%2F1g6.webp?alt=media&token=5cc91215-f856-4765-8c6a-de23724e176a",
    },
    {
      src: "https://firebasestorage.googleapis.com/v0/b/ttovarischh-9c624.appspot.com/o/quimi%2Fslides%2F2g7.webp?alt=media&token=3ea4f4d5-e9da-461f-a502-cead92256d82",
    },
    {
      src: "https://firebasestorage.googleapis.com/v0/b/ttovarischh-9c624.appspot.com/o/quimi%2Fslides%2F3g8.webp?alt=media&token=6885c6da-1cee-4953-bf51-112e225cfc46",
    },
    {
      src: "https://firebasestorage.googleapis.com/v0/b/ttovarischh-9c624.appspot.com/o/quimi%2Fslides%2Far_posters.mp4?alt=media&token=5d85f4ac-687a-48cc-b758-c82478888ab9",
      shouldAutoplay: true,
    },
    {
      src: "https://firebasestorage.googleapis.com/v0/b/ttovarischh-9c624.appspot.com/o/quimi%2Fslides%2Fg6.webp?alt=media&token=dd52c5c1-b019-4d3f-8d9b-2eea0a98a36a",
    },
    {
      src: "https://firebasestorage.googleapis.com/v0/b/ttovarischh-9c624.appspot.com/o/quimi%2Fslides%2Fg7.webp?alt=media&token=87a5be0b-3076-4cd2-95a9-0729a4a049b7",
    },
    {
      src: "https://firebasestorage.googleapis.com/v0/b/ttovarischh-9c624.appspot.com/o/quimi%2Fslides%2Flanding.mp4?alt=media&token=65999242-c834-47b2-8bf9-6eec6e2616ad",
      poster:
        "https://firebasestorage.googleapis.com/v0/b/ttovarischh-9c624.appspot.com/o/quimi%2Fslides%2Fpoater.webp?alt=media&token=a5242a4d-51cb-45fd-a6af-30dc2c1b8332",
    },
    {
      src: "https://firebasestorage.googleapis.com/v0/b/ttovarischh-9c624.appspot.com/o/quimi%2Fslides%2Flastg2.webp?alt=media&token=b9196708-ee8c-4fc0-b2a9-c1238893a8b5",
    },
    {
      src: "https://firebasestorage.googleapis.com/v0/b/ttovarischh-9c624.appspot.com/o/quimi%2Fslides%2Flastg3.webp?alt=media&token=4eb794de-7e5f-4d19-884e-1c79dc7705f5",
    },
    {
      src: "https://firebasestorage.googleapis.com/v0/b/ttovarischh-9c624.appspot.com/o/quimi%2Fslides%2Fstories.mp4?alt=media&token=db45483e-8dcb-4376-a745-6ba6575a1d17",
      shouldAutoplay: true,
    },
  ],
  texts: [
    {
      header: [{ en: "Semantics of naming", ru: "Семантика нейминга" }],
      text: [
        {
          en: "The word «quimi» itself doesn’t mean anything. We chose it because of its pleasant, somewhat magical sound. Quimi represents the traces left by users on the map. Each one is unique and a little bit strange, just like the app’s name.",
          ru: "Слово «quimi» само по себе ничего не означает. Мы выбрали его из-за приятного, несколько волшебного звучания. Quimi — это следы, оставляемые пользователями на карте. Все они уникальные и немного странные, как и само название приложения.",
        },
      ],
    },
    {
      header: [{ en: "Target audience", ru: "Целевая аудитория" }],
      text: [
        {
          en: "The main TA segment consists of people who walk a lot. They’ve already explored all the routes inside and out and are looking for something new. The secondary segment, on the contrary, rarely goes for walks. They either don’t know of interesting spots or simply need something inspiring.",
          ru: "Основным сегментом ЦА стали люди, которые гуляют много. Они прошли уже все излюбленные маршруты вдоль и поперёк. Им хочется чего‑то нового. Косвенный сегмент, наоборот, гуляет редко. Они не знают занимательных мест или просто не видят в этом интереса. Им нужно что-то вдохновляющее.",
        },
      ],
    },
    {
      header: [{ en: "About identity", ru: "Про фирменный стиль" }],
      text: [
        {
          en: "The brand style metaphor is about stepping beyond the boundaries of reality through 3D objects. Additional graphics include photographs of the sky and renders of different Quimis. On one side of the frame, the renders are always cut off by the sky’s edge, while on the other, they always extend beyond the limits.",
          ru: "Метафора стиля — выход за рамки реальности через 3d объекты. В качестве дополнительной графики используются фотографии неба и рендеры разных квими. С одной стороны рендеры всегда режутся рамкой неба, а с другой — всегда выходят за границы.",
        },
      ],
    },
    {
      header: [
        {
          en: "About promotion",
          ru: "Про продвижение",
        },
      ],
      text: [
        {
          en: "To promote the brand, a grid of posts for social media was created, along with a series of AR posters, a collection of advertising stories, and an interactive landing page.",
          ru: "Для продвижения бренда была создана сетка постов для соцсетей, серия AR-постеров, коллекция рекламных сторис и интерактивный лендинг.",
        },
      ],
    },
  ],
  layout: [
    {
      component: "EmblaCarousel",
      sliderIndex: 0,
      references: "about",
    },
    {
      component: "A_InfoBlock",
      textIndex: 0,
      $body: true,
      references: "naming",
    },
    {
      component: "EmblaCarousel",
      sliderIndex: 1,
      references: "cusdev",
    },
    {
      component: "M_CardsBlocksGrid",
      smallCardIndex: [0, 1, 2],
      references: "competitors",
    },
    {
      component: "A_InfoBlock",
      textIndex: 1,
      $body: true,
      references: "ta",
    },
    {
      component: "M_CaseImagesGrid",
      imageIndices: [0, 1],
      references: "jobstories",
    },
    {
      component: "M_LinkCardsGrid",
      linkCardIndex: [0, 1],
      references: "research",
    },
    {
      component: "M_CaseImagesGrid",
      imageIndices: [2, 3, 4],
      references: "product",
    },
    {
      component: "M_LinkCardsGrid",
      linkCardIndex: [2, 3, 4],
      references: "scenarios",
    },
    {
      component: "EmblaCarousel",
      sliderIndex: 2,
      references: "screens",
    },
    {
      component: "M_CaseImagesGrid",
      imageIndices: [5, 6, 7],
      references: "features",
    },
    {
      component: "A_InfoBlock",
      textIndex: 2,
      $body: true,
      references: "identity",
    },
    {
      component: "M_CaseImagesGrid",
      imageIndices: [8, 9, 10],
      references: "dassets",
    },
    {
      component: "EmblaCarousel",
      sliderIndex: 3,
      references: "assets1",
    },
    {
      component: "A_InfoBlock",
      textIndex: 3,
      $body: true,
      references: "promotion",
    },
    { component: "M_CaseImagesGrid", imageIndices: [11, 12, 13, 14] },
    {
      component: "EmblaCarousel",
      sliderIndex: 4,
    },
  ],
  menuItems: [
    {
      header: [
        {
          title: {
            en: "—\u2002About Quimi",
            ru: "—\u2002О проекте",
          },
          reference: "about",
        },
      ],
      subItems: [
        {
          title: {
            en: "Naming",
            ru: "Нейминг",
          },
          reference: "naming",
        },
        {
          title: {
            en: "Custdev",
            ru: "Касдев",
          },
          reference: "cusdev",
        },
        {
          title: {
            en: "Competitors",
            ru: "Конкуренты",
          },
          reference: "competitors",
        },
        {
          title: {
            en: "Target audience",
            ru: "Целевая аудитория",
          },
          reference: "ta",
        },
        {
          title: {
            en: "Jobstories & portraits",
            ru: "Jobstories & портреты",
          },
          reference: "jobstories",
        },
        {
          title: {
            en: "Research & atomic",
            ru: "Ресерч & aтомик",
          },
          reference: "research",
        },
      ],
    },
    {
      header: [
        {
          title: {
            en: "—\u2002Product",
            ru: "—\u2002Продукт",
          },
          reference: "product",
        },
      ],
      subItems: [
        {
          title: {
            en: "User scenarios",
            ru: "Пользовательские сценарии",
          },
          reference: "scenarios",
        },
        {
          title: {
            en: "Some screens",
            ru: "Некоторые экраны",
          },
          reference: "screens",
        },
        {
          title: {
            en: "Features",
            ru: "Фишки",
          },
          reference: "features",
        },
      ],
    },
    {
      header: [
        {
          title: {
            en: "—\u2002Branding & Identity",
            ru: "—\u2002Брендинг и айдентика",
          },
          reference: "identity",
        },
      ],
      subItems: [
        {
          title: {
            en: "Digital assets",
            ru: "Диджитальные носители",
          },
          reference: "dassets",
        },
        {
          title: {
            en: "Physical merch",
            ru: "Физические носители",
          },
          reference: "assets1",
        },
        {
          title: {
            en: "More merch & promotion",
            ru: "Больше носителей и продвижение",
          },
          reference: "promotion",
        },
      ],
    },
  ],
};
