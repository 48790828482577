import React, { forwardRef } from "react";
import { useTranslation } from "react-i18next";

const A_Tooltip = forwardRef<HTMLDivElement>((props, ref) => {
  const { t } = useTranslation();
  return (
    <div ref={ref} className="tooltip">
      {t("works.locked")}
    </div>
  );
});

export default React.memo(A_Tooltip);
