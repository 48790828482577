export const darkTheme = {
  white: "#FBF8F3",
  black: "#111111",
  main_grey: "#212121",
  medium_grey: "#6B6863",
  light_grey: "#3f3f3f",
  lightest_grey: "#595754",
  gradient:
    "linear-gradient(180deg,rgba(17, 17, 17, 0.9) 0%,rgba(17, 17, 17, 0) 100%)",
  footer: {
    bg: "#FBF8F3",
  },
  page_menu: {
    bg: "rgba(57, 57, 57, 0.4)",
    text: "#82807D",
    text_active: "#FBF8F3",
    line: "#FFFFFF",
    text_bg: "rgba(255, 255, 255, 0.2)",
  },
  navigation: {
    backtotop: "rgba(57, 57, 57, 0.4)",
    navbuttonswrapper: "rgba(57, 57, 57, 0.2)",
    hoverbg: "rgba(255, 255, 255, 0.4)",
    themeswitcher: "rgba(57, 57, 57, 0.6)",
  },
  tags: {
    headertagbg: "#111",
    bg: "#3F3F3F",
    selected: "rgba(57, 57, 57, 0.4)",
    selectedBorder: "none",
  },
  embla: {
    dot: "#212121",
    dot_active: "#2d2d2d",
    button: "#FBF8F3",
  },
  cards: {
    bg: "#282828",
    lighter_bg: "#2D2D2D",
    shadow: "0px 10px 30px rgba(0, 0, 0, 0.6)",
    staticShadow: "none",
    staticShadowHover: "0px 10px 30px rgba(0, 0, 0, 0.6)",
    horisontalShadow: "none",
    verticalShadow: "0px 0px 15px rgba(0, 0, 0, 0.7)",
    imageShadow: "transparent",
    aboutShadow:
      "0px 1px 2px 0px rgba(0, 0, 0, 0.3), 0px 1px 3px 1px rgba(0, 0, 0, 0.15)",
    aboutShadowHover: "0px 10px 30px rgba(0, 0, 0, 0.6)",
  },
  buttons: {
    bigButton: "#212121",
    smallButton: "#3f3f3f",
    shadow: "0px 0px 15px rgba(0, 0, 0, 0.4)",
  },
  cardBlocks: {
    line: "#595754",
    icon: "#595754",
  },
  mainComp: {
    header: "#111111",
    text: "#595754",
    selection: "#FBF8F3",
  },
  boxShadows: {
    projectCards: "rgba(0, 0, 0, 0.6)",
  },
  body: "#111111",
};

export const lightTheme = {
  white: "#111111",
  black: "#FBF8F3",
  main_grey: "#212121",
  medium_grey: "#A29F9B",
  light_grey: "#3f3f3f",
  lightest_grey: "#A29F9B",
  embla_dot: "#212121",
  embla_dot_active: "#2d2d2d",
  gradient: "transparent",
  // gradient:
  //   "linear-gradient(180deg,rgba(251, 248, 243, 0.5) 0%,rgba(251, 248, 243, 0) 100%)",
  footer: {
    bg: "#E6E3DE",
  },
  page_menu: {
    bg: "rgba(57, 57, 57, 0.2)",
    text: "#82807D",
    text_active: "#212121",
    line: "#82807D",
    text_bg: "rgba(251, 248, 244, 0.4)",
  },
  navigation: {
    backtotop: "rgba(57, 57, 57, 0.2)",
    navbuttonswrapper: "rgba(57, 57, 57, 0.2)",
    hoverbg: "rgba(255, 255, 255, 0.4)",
    themeswitcher: "rgba(57, 57, 57, 0.4)",
  },
  tags: {
    headertagbg: "#FBF8F3",
    bg: "#C2BFBA",
    selected: "#FBF8F3",
    selectedBorder: "1px solid hsl(0deg 0% 65.81% / 20%)",
  },
  embla: {
    dot: "#E6E3DE",
    dot_active: "#C2BFBA",
    button: "#FBF8F3",
  },
  cards: {
    bg: "#E6E3DE",
    lighter_bg: "#C2BFBA",
    shadow: "transparent",
    staticShadow:
      "0px 1px 2px 0px rgba(0, 0, 0, 0.2), 0px 1px 3px 1px rgba(0, 0, 0, 0.15)",
    staticShadowHover:
      "0px 1px 2px 0px rgba(0, 0, 0, 0.2), 0px 1px 3px 1px rgba(0, 0, 0, 0.15)",
    horisontalShadow:
      "0px 1px 2px 0px rgba(0, 0, 0, 0.2), 0px 1px 3px 1px rgba(0, 0, 0, 0.15)",
    verticalShadow: "0px 0px 20px 0px rgba(0, 0, 0, 0.2)",
    imageShadow: "0px 0px 20px 0px rgba(0, 0, 0, 0.2)",
    aboutShadow:
      "0px 1px 2px 0px rgba(0, 0, 0, 0.2), 0px 1px 3px 1px rgba(0, 0, 0, 0.15)",
    aboutShadowHover:
      "0px 1px 2px 0px rgba(0, 0, 0, 0.2), 0px 1px 3px 1px rgba(0, 0, 0, 0.15)",
  },
  buttons: {
    bigButton: "#E6E3DE",
    smallButton: "#E6E3DE",
    shadow: "0px 0px 15px rgba(0, 0, 0, 0.0)",
  },
  cardBlocks: {
    line: "#C2BFBA",
    icon: "#C2BFBA",
  },
  mainComp: {
    header: "#111111",
    text: "#595754",
    selection: "#FBF8F3",
  },
  boxShadows: {
    projectCards: "transparent",
  },
  body: "#FAF6EF",
};
