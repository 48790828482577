import { Project } from "../types";

export const DSTSyhth: Project = {
  id: 5,
  folder: "dst",
  name: {
    en: "DST Synth",
    ru: "DST Synth",
  },
  similarCases: [8, 7, 6],
  type: {
    en: "Personal",
    ru: "Личное",
  },
  timeline: {
    en: "Sep'22 — Oct'22",
    ru: "Сен'22 — Окт'22",
  },
  year: "2022",
  short_deliverables: {
    en: "UI/UX & Dev",
    ru: "UI/UX & Разработка",
  },
  deliverables: {
    en: "UI/UX, Development",
    ru: "UI/UX, Разработка",
  },
  filterTags: [
    {
      name: { en: "UI/UX design", ru: "UI/UX" },
      value: "uiux",
    },
    {
      name: { en: "Development", ru: "Разработка" },
      value: "dev",
    },
  ],
  role: {
    en: "UI/UX designer, Developer",
    ru: "UI/UX дизайнер, разработчик",
  },
  organisation: "HSE ART AND DESIGN SCHOOL",
  status: {
    en: "Finished",
    ru: "Завершено",
  },
  s_description: {
    en: "This web synthesizer is my reflection on the reality of this day.",
    ru: "Этот веб-синтезатор — моя рефлексия на реальность сегодняшнего дня.",
  },
  description: {
    en: "Recently, most of my friends have moved to other countries. We can only communicate via Zoom or Telegram. In this situation, the specificity of our auditory backgrounds suddenly became obvious. Synth is an attempt to synthesize this strange music that is created during our current communication.",
    ru: "За последнее время большинство моих друзей переехали в другие страны. Мы можем общаться только через Zoom или Telegram. В этой ситуации стала вдруг очевидна специфика наших аудиальных бэкграундов. Синт — попытка синтезировать эту странную музыку, которая создаётся во время нашего нынешнего общения.",
  },
  vertical_cover:
    "https://firebasestorage.googleapis.com/v0/b/ttovarischh-9c624.appspot.com/o/dst%2FdstVertiCover.webp?alt=media&token=5dd6ea52-c21e-44f7-90a8-ae4175b04e9b",
  horisontal_cover:
    "https://firebasestorage.googleapis.com/v0/b/ttovarischh-9c624.appspot.com/o/casesCovers%2Fdstcover.webp?alt=media&token=0fb5ff41-dc87-4fbd-b999-b3fd95bcb3b1",
  cover:
    "https://firebasestorage.googleapis.com/v0/b/ttovarischh-9c624.appspot.com/o/dst%2FCover.mp4?alt=media&token=fd76769e-e970-46e0-ada8-2189137f001c",
  cover_mob:
    "https://firebasestorage.googleapis.com/v0/b/ttovarischh-9c624.appspot.com/o/dst%2FVertiCover.mp4?alt=media&token=1d2d2ee8-1b7d-4b82-ba38-aedd8cda6b2e",
  // cover: "Cover.mp4",
  // cover_mob: "VertiCover.mp4",
  links: [
    {
      name: {
        en: "Full presentation",
        ru: "Презентация",
      },
      url: "https://portfolio.hse.ru/Project/155251",
    },
    {
      name: {
        en: "Behance",
        ru: "Behance",
      },
      url: "https://www.behance.net/gallery/207084539/Digital-Sound-Trip-Synth",
    },
  ],
  slider: [
    {
      slides: [
        {
          imgSrc:
            "https://firebasestorage.googleapis.com/v0/b/ttovarischh-9c624.appspot.com/o/dst%2Fslides%2F1.webp?alt=media&token=25c47dab-cf45-4f62-b5f9-fb52481fc128",
          description: {
            en: "Context",
            ru: "Контекст",
          },
        },
        {
          imgSrc:
            "https://firebasestorage.googleapis.com/v0/b/ttovarischh-9c624.appspot.com/o/dst%2Fslides%2F2.webp?alt=media&token=ced4ed3a-eb88-4a38-bdd0-5e9f997192ae",
          description: {
            en: "Problem",
            ru: "Проблема",
          },
        },
        {
          imgSrc:
            "https://firebasestorage.googleapis.com/v0/b/ttovarischh-9c624.appspot.com/o/dst%2Fslides%2F3.webp?alt=media&token=9a4afe4c-db54-4545-ad5c-fd2a171cb4fb",
          description: {
            en: "Idea",
            ru: "Идея",
          },
        },
      ],
    },
    {
      slides: [
        {
          imgSrc:
            "https://firebasestorage.googleapis.com/v0/b/ttovarischh-9c624.appspot.com/o/dst%2Fslides%2F6.webp?alt=media&token=c0f4d0c1-a24d-44f5-8d42-f3a19c023100",
          description: {
            en: "About visual style",
            ru: "Про визуальный стиль",
          },
        },
        {
          imgSrc:
            "https://firebasestorage.googleapis.com/v0/b/ttovarischh-9c624.appspot.com/o/dst%2Fslides%2F7.webp?alt=media&token=74815f32-7906-4603-b437-413a4e8ca07d",
          description: {
            en: "About visual style II",
            ru: "Про визуальный стиль II",
          },
        },
        {
          imgSrc:
            "https://firebasestorage.googleapis.com/v0/b/ttovarischh-9c624.appspot.com/o/dst%2Fslides%2F9.webp?alt=media&token=eb198af1-e579-44d0-9f28-339c39f0fb06",
          description: {
            en: "Typography and palette",
            ru: "Типографика и палитра",
          },
        },
        {
          imgSrc:
            "https://firebasestorage.googleapis.com/v0/b/ttovarischh-9c624.appspot.com/o/dst%2Fslides%2F8.webp?alt=media&token=9a40b761-2fcb-4931-8bba-504ad561cc06",
          description: {
            en: "Grid",
            ru: "Сетка",
          },
        },
      ],
    },
  ],
  link_cards: [
    {
      image_src:
        "https://firebasestorage.googleapis.com/v0/b/ttovarischh-9c624.appspot.com/o/dst%2Fslides%2Flinktogit2.webp?alt=media&token=2ec1949c-4a75-4d06-96d7-1d9e32db1392",
      header: [{ en: "Github", ru: "Github" }],
      text: [
        {
          en: "The synthesizer’s code is located in an open Github repository. If you want, you can run it locally.",
          ru: "Код синтезатора находится в открытом репозитории на Github. При желании его можно запустить локально.",
        },
      ],
      link_text: [{ en: "Go to repo", ru: "К репозиторию" }],
      url: "https://github.com/ttovarischh/DigitalSoundTrip",
    },
  ],
  images: [
    {
      src: "https://firebasestorage.googleapis.com/v0/b/ttovarischh-9c624.appspot.com/o/dst%2Fslides%2F4b%202.mp4?alt=media&token=746e3db3-f68e-4fbc-980d-734f348c5264",
      shouldAutoplay: true,
    },
    {
      src: "https://firebasestorage.googleapis.com/v0/b/ttovarischh-9c624.appspot.com/o/dst%2Fslides%2F4.webp?alt=media&token=f90e0394-671e-42cb-ac17-b6e7ecdc1d04",
    },
    {
      src: "https://firebasestorage.googleapis.com/v0/b/ttovarischh-9c624.appspot.com/o/dst%2Fslides%2F5.webp?alt=media&token=7a8f943f-7ec6-4016-9414-c28eda1c63ee",
    },
    {
      src: "https://firebasestorage.googleapis.com/v0/b/ttovarischh-9c624.appspot.com/o/dst%2Fslides%2F5d%20site.mp4?alt=media&token=dd4946fe-1f5d-4eef-98d5-df235d63d69c",
      shouldAutoplay: true,
    },
    {
      src: "https://firebasestorage.googleapis.com/v0/b/ttovarischh-9c624.appspot.com/o/dst%2Fslides%2Fdigital_sound_trip___synth%20(720p)%20(1).mp4?alt=media&token=cf123227-6bc0-41f7-9b5a-b4bf371530f1",
      poster:
        "https://firebasestorage.googleapis.com/v0/b/ttovarischh-9c624.appspot.com/o/dst%2Fslides%2Fposter.webp?alt=media&token=79a0adb8-7ead-475f-bab7-08ea947a3f43",
    },
    {
      src: "https://firebasestorage.googleapis.com/v0/b/ttovarischh-9c624.appspot.com/o/dst%2Fslides%2Fsite%20fin.mp4?alt=media&token=0191dd15-aa9c-4675-9fc3-3953d5def682",
      shouldAutoplay: true,
    },
  ],
  texts: [
    {
      header: [{ en: "What's that", ru: "Что это" }],
      text: [
        {
          en: "DST is a small but proud web synthesizer. It’s a tool for creating musical compositions right in your browser. As part of my creative coding course, we studied Tone.js. This is the result of my work for the first module.",
          ru: "DST — небольшой, но гордый веб-синтезатор. Это инструмент для создания музыкальных композиций прямо в вашем браузере. В рамках моего курса по креативному кодингу мы изучали Tone.js. Это результат моей работы в первом модуле.",
        },
      ],
    },
    {
      header: [{ en: "About style", ru: "Про стиль" }],
      text: [
        {
          en: "Of course, design, along with working with sound, was a very important part of the work. It was necessary to show the digital vibe of the project, while remaining in a cozy space. I wanted something warm then. For the first time in a long time I used pastel colors.",
          ru: "Конечно, дизайн, наряду со звуком, был очень важной частью работы. Нужно было передать цифровую атмосферу проекта, оставаясь при этом в пространстве уютного, домашнего. Хотелось тогда чего‑то тёплого. Впервые за долгое время я использовала пастельные тона.",
        },
      ],
    },
  ],
  layout: [
    {
      component: "EmblaCarousel",
      sliderIndex: 0,
      references: "about",
    },
    {
      component: "A_InfoBlock",
      textIndex: 0,
      $body: true,
      references: "task",
    },
    {
      component: "M_CaseImagesGrid",
      imageIndices: [0, 1, 2, 3],
      references: "product",
    },
    {
      component: "A_InfoBlock",
      textIndex: 1,
      $body: true,
      references: "style",
    },
    {
      component: "EmblaCarousel",
      sliderIndex: 1,
      references: "guide",
    },
    {
      component: "M_CaseImagesGrid",
      imageIndices: [4],
      references: "screencast",
    },
    {
      component: "M_LinkCardsGrid",
      linkCardIndex: [0],
      references: ["repo"],
    },
    {
      component: "M_CaseImagesGrid",
      imageIndices: [5],
      references: "important",
    },
  ],
  menuItems: [
    {
      header: [
        {
          title: {
            en: "—\u2002About DST",
            ru: "—\u2002О проекте",
          },
          reference: "about",
        },
      ],
      subItems: [
        {
          title: {
            en: "Task",
            ru: "Задача",
          },
          reference: "task",
        },
      ],
    },
    {
      header: [
        {
          title: {
            en: "—\u2002Product, sound structure, controls, features",
            ru: "—\u2002Продукт",
          },
          reference: "product",
        },
      ],
    },
    {
      header: [
        {
          title: {
            en: "—\u2002Visual style",
            ru: "—\u2002Визуальный стиль",
          },
          reference: "style",
        },
      ],
      subItems: [
        {
          title: {
            en: "Guide",
            ru: "Гайд",
          },
          reference: "guide",
        },
        {
          title: {
            en: "Screencast",
            ru: "Скринкаст",
          },
          reference: "screencast",
        },
        {
          title: {
            en: "Repo",
            ru: "Репозиторий",
          },
          reference: "repo",
        },
        {
          title: {
            en: "Important note",
            ru: "Заметки на полях",
          },
          reference: "important",
        },
      ],
    },
  ],
};
